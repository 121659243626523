.card {
    border: 1px dashed $black;
    .text-white &,
    .text-light & {
        border: 1px dashed $white;
    }
    border-radius: 1rem !important;    overflow: visible;
    background: none;
    position: relative;

    .card-img-top {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }

    .card-body {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        font-family: $font-family-serif;

        @extend .pl-0;
        @extend .pr-0;
        @extend .py-2;
        @extend .ml-0;

        // min-height: 275px;

        h2 {
            .content-small & {
                font-size: 1.4rem;
            }
        }

        h4 {
            @extend .mb-1;
            font-weight: $font-weight-light;

            .content-small & {
                font-size: .85rem;
            }
        }

        p {
            @extend .lead;
            font-family: $font-family-base !important;
            margin-bottom: 1em !important;

            .content-small & {
                font-size: 1rem;
            }

        }

        .small {
            @extend .h4;
            @extend .mb-1;
            font-size: 0.7em !important;
        }
    }

    .attribution {
        @extend .small;
        @extend .mt-3;

        &:before {
            content: '';
            display: block;
            width: 40px;
            border-top: 4px solid theme-color('primary');
            margin-bottom: 1em;
        }
    }

    &:hover {
        /* offset-x | offset-y | blur-radius | spread-radius | color */
        // box-shadow: 0px 0px 30px 10px lighten($black, 85%);
    }

    p.moreInfo {
        color: theme-color(primary-2) !important;
        margin-bottom: 0 !important;
    }

    &--top-story {
        // border: 2px solid red;
        // position: relative;

        .card-body {
            position: absolute;
            bottom: 0;
            @extend .p-4;
        }

        p.teaser, .teaser .neos-inline-editable,
        h2, h2 .neos-inline-editable {
            background-image: linear-gradient(to bottom, transparent 0, theme-color('primary') 0);
            display: inline;
            width: 100%;
            box-decoration-break: clone;
            background-size: 100% 1.75em;
            background-position: 0 center;
            background-repeat: no-repeat;
            margin-left: -10px;
            padding: 0 10px;
        }
    
        .title-spacer {
            @extend .mb-1;
        }

        h4, 
        .nav--features,
        .moreInfo {
            @extend .d-none;
        }
    }
}

.card.card--infoBox {
    border: 1px dashed $black;
    .text-white &,
    .text-light & {
        border: 1px dashed $white;
    }
    border-radius: 1rem !important;
    &.process {
        border: none;
        img {
            max-width: 80px;
            margin: 0 auto !important;
            @extend .d-block;
        }
        h3 {
            font-size: 1.5rem;
        }
    }

    .has-icon {
        .card-img-top {
            width: 100px !important;
        }
    }

    .card-body {
        @extend .p-3;
        @extend .text-center;
    }
    p {
        font-family: $font-family-sans-serif !important;
        font-size: 1rem !important;
        color: $black;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.blogListItem {
    @extend .card;
    @extend .bg-dark;
    @extend .text-light;
    @extend .hover-shadow-3d;

    .itemInfo {
        color: theme-color(primary-3);
        @extend .mb-2;
    }

    p.title {
        color: $white !important;
        @extend .mb-2;
    }

    p.moreInfo {
        color: theme-color(primary) !important;
    }

}
