main {

    ul,
    ol {
        padding-left: 1.5em;
    }

    ul {
        list-style-type: none;

        padding-left: 1.25em;

        li:not(.nav-item) {
            &:before {
                content: '➔' !important;
                position: absolute;
                margin-left: -1.5em;
                color: theme-color('primary-2');
            }
        }
    }

    ol:not(.breadcrumb) {
        counter-reset: li;
        /* Initiate a counter */
        padding-left: 3em;
        list-style-type: none !important;

        li {
            position: relative;
            /* Disable the normal item numbering */
            @extend .mb-3;

            &:before {
                content: counter(li);
                /* Use the counter as content */
                counter-increment: li;
                /* Increment the counter by 1 */
                /* Position and style the number */
                position: absolute;
                top: 0;
                left: -3em;
                overflow: hidden;
                box-sizing: border-box;
                width: 1.75em;
                height: 1.75em;
                border-radius: 50%;
                color: #fff;
                padding-top: 0px;
                background: theme-color('primary');
                text-align: center;
            }
        }
    }

}


.ol--counter {

    // Für Impressum etc.
    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    ol>li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }

    ol>li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }
}