.backdrop-image {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.background--header {
    position: absolute;
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    font-size: 240px;
    .textBlock,
    span {
        white-space: nowrap;
    }
    .header &{
        top: 50%;
    }
}

.header {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;

    overflow: hidden;

    padding-top: $top-offset !important;

    text-align: center;

    .header-content {
        margin-bottom: -1px;
        // min-height: 350px;
        // height: 600px;

        // @media (min-width: 1366px) and (max-width: 1680px) {
        //     min-height: 280px;
        // }

        // @media (min-width: 960px) and (max-width: 1366px) {
        //     min-height: 220px;
        // }

        // @include media-breakpoint-down(md) {
        //     min-height: 180px;
        // }
    }
    
    .lead {
        @extend .mb-1;
        font-size: 1.5rem;
    }

    .btn {
        // font-size: 1.25em !important;
    }

    
    &--home {
        .header-content {
            .row {
                margin-bottom: -1px !important;
            }
        }
    }
    &--home,
    &--page {
        .bg-image {
            width: initial !important;
            height: 85% !important;
            top: 15%;
            // left: 52%;
        }

        @extend .header-inner;
        @extend .p-0;

        .header-content {
            @extend .container;
            @extend .py-0;

            .row {
                @extend .my-4;
                @extend .my-md-7;
            }
        }

        h1 {
            @extend .display-3;
        }

        p {
            &.h1 {
                @extend h1;
                color: theme-color('primary-2') !important;
            }
        }
    }

    &--page {
        h1 {
            @extend .display-4;
        }
        .header-content {
            .row {
                padding-top: 5%;
                padding-bottom: 5%;
            }
        }
    }

    &--story {
        // border: 2px solid  red;
        @extend .pt-6;
        @extend .mb-0;
        @extend .bg-white;
        min-height: 700px;
        background-repeat: no-repeat;
        background-size: cover;

        h1 {
            color: theme-color('primary');
        }

        .header-content {
            min-height: initial;
        }
    }

}
