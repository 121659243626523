.sharing {
    $button-size: 2.75em;
    &--buttons {
        position: absolute;
        top: 0;
        left: -$button-size;
        ul {
            @extend .p-0;
            list-style-type: none;
            background: theme-color(primary);
            list-style-type: none;
            li {
                text-align: center;
                width: $button-size;
                height: $button-size;
                position: relative;
                @extend .d-flex;
                @extend .justify-content-center;
                &:hover {
                    i {
                        transition: all 0.15s ease-out;
                        font-size: 1.75em;
                        color: theme-color('primary-2');
                    }
                }
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 1px;
                    width: 60%;
                    left: 20%;
                    border-bottom: 1px solid $white;
                    margin: 0 auto;
                    display: block;
                }
                &:last-child {
                    &:after {
                        @extend .d-none;
                    }
                }
                a {
                    @extend .align-self-center;
                }
            }
        }
    }
}

.btn {
    transition: all .1s ease-in-out;
    @extend p;
    @extend .btn-primary;
    @extend .bg-gradient-primary;
    @extend .py-2;
    @extend .px-3;
    border: none;
    border-radius: 6px !important;
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
    letter-spacing: 0;
    font-size: $font-size-base;
    // text-transform: uppercase;

    &:hover {
        cursor: pointer;
        color: $white !important;
        transform: scale(1.05);
    }

    &.btn-light,
    &.btn-white {
        background: $white;
        color: theme-color('primary');
        &:hover {
            color: theme-color('primary') !important;
        }
    }

    &.btn-outline-primary {
        background: none;
        border: $border-width solid theme-color('primary-3');
        font-weight: 400;
        color: theme-color('primary-3') !important;
        .bg-primary-2 & {
            color: $white;
        }
    }
}

.navbar {
    .btn {
        @extend .btn-primary;
        border: 2px solid theme-color('primary-3');
        background: none;
        color: theme-color('primary');

    }
}






$squareButtonSize: 60px;
.btn-group {
    .btn {
    @extend .btn-sm;
    @extend .btn-primary;
    @extend .btn-outline-dark;
    font-weight: 400;
    font-size: .9rem;
    border: 1px solid $black;
    &.active {
        background-color: $black !important;
    }
    &:hover {
        background-color: $white;
        color: $black;
    }
    @include media-breakpoint-down(md) {
        padding: 8px 10px 6px;
    }
    @include media-breakpoint-down(sm) {
        font-size: 13px;
    }
    }
}
.button {
    font-family: 'Roboto Condensed', sans-serif;
    @extend .btn;
    @extend .btn-primary;
    @extend .btn-lg;

    @include media-breakpoint-down(md) {
        font-size: .9rem;
        font-weight: 400;
    }
    @include media-breakpoint-down(sm) {
        font-size: 13px;
        font-weight: 400;
    }

    &:hover {
        color: $white !important;
        background: adjust-hue(theme-color('primary'), -35deg);
        box-shadow: 0 3px 20px rgba($black,0.15)
    }

    &.outline {
        @extend .btn-outline-primary;
    }

    &.borderline {
        position: absolute;
        z-index: 999;
        bottom: -1.5em;
        left: 0;
    }

    &.button-square {
        width: $squareButtonSize;
        height: $squareButtonSize;
        @extend .p-0;
        position: absolute;
        z-index: +999;
        bottom: 0;
        right: -$squareButtonSize/2;

        @include media-breakpoint-down(md) {
            height: 45px;
            width: 45px;
            svg {
                height: 45px;
                width: 45px;
            }
        }
        @include media-breakpoint-down(sm) {
            height: 40px;
            width: 40px;
            svg {
                height: 40px;
                width: 40px;
            }
        }
    }
}