.navbar-container {
    font-family: $font-family-base;
    .navbar {
        background-color: theme-color('primary-5') !important;
        @extend .py-2;

        .navbar-brand {
            @extend .my-auto;
            transition: all .3s ease-in-out;
            margin-right: 0;
            position: absolute;
            padding-top: 1em;
            width: 220px;
        }

        &.scrolled {
            .navbar-brand {
                width: 200px !important;
                padding-top: 0;
            }
        }
    }

    .navbar-toggler {
        @extend .ml-auto;
        .icon {
            height: 2.5rem;
        }
    }

    .first-level {
        &.nav-link {
            color: theme-color('primary-2');
        }
    }

    .nav-item {
        font-size: 1.25rem;
        a {
            &.nav-link {

                white-space: nowrap;
                color: theme-color('primary-2');

                &.is-active,
                &.is-currend {
                    // color: theme-color('primary') !important;
                }

                &:hover {
                    color: theme-color('primary');
                }
            }
        }

        &:hover,
        &.show {
            a {
                &.nav-link {
                    // color: theme-color('primary-3') !important;

                    // .bordered {
                    //     border-bottom: 2px solid rgba($primary-3, 0);
                    //     &:hover {
                    //         // @extend .pb-2;
                    //         border-bottom: 2px solid theme-color('primary-3');
                    //     }
                    // }
                }
            }
        }

        .dropdown-grid-menu {
            @extend .pt-1;
            border-radius: 0;
            border: none;
            background: white;
        }
    }

    .dropdown-grid-menu {
        @extend .p-0;
        min-width: 250px;
    }

    .dropdown-item {
        @extend .px-2;
        @extend .py-1;
        font-weight: $font-weight-normal;
        color: theme-color('primary-3');

        .icon {
            display: initial;
        }

        span {
            border-bottom: none;
        }

        &:hover {
            background-color: theme-color('light');
            color: white;

            span {
                color: theme-color('primary-2');
            }

            &.is-current {
                color: theme-color('primary');
            }
        }
    }

    .is-active,
    .is-current {
        color: theme-color('primary-2') !important;

        &.dropdown-item {
            color: theme-color('primary') !important;
        }
    }

    .navToggle {
        background-color: none;

        svg {
            transform: rotate(-90deg);
        }
    }

    .btn {
        @extend .mt-2;
    }

    button {
        &.navbar-toggler {
            @extend .my-auto;
            height: 100%;
            padding: $grid-gutter-width/3;
            // width: 3rem;
            // border: 2px solid theme-color('primary') !important;
            border-radius: .325em;
            background: $white;
            &:focus {
                outline: none;
            }

            path {
                fill: $primary-3;
                
            }

            @include media-breakpoint-down (sm) {
                padding: $grid-gutter-width/3;
                transform: scale(.8);
            }
        }

        .navbar-toggler-open,
        .navbar-toggler-close {}

        .navbar-toggler-close {
            left: calc(50% - 1.5rem);
        }
    }
}
.nav {
    .nav-item {
        &:first-child {
            .nav-link {
                @extend .pl-0;
            }
        }

        &:last-child {
            .nav-link {
                @extend .pr-0;
            }
        }
    }

    &--main {
        .nav-link {
            font-weight: $font-weight-normal;
            color: theme-color(primary-3) !important;
            .bordered {
                transition: all .2s ease-out;
                border-bottom: 2px solid rgba($primary-3, 0);
                @extend .pb-1;
                &:hover {
                    border-bottom: 2px solid rgba($primary-3, 1);
                }
            }
            &.is-current {
                color: theme-color(primary-3) !important;
                .bordered {
                    border-bottom: 2px solid theme-color('primary-3');
                    @extend .pb-1;
                }
            }
        }
    }

    &--spy {
        .nav-link {
            .nav-icon {
                filter: grayscale(100%);
                // filter: grayscale(100%) blur(1px);
                transition: all .2s ease-in-out;
                height: 90px;
                @extend .d-flex;
                @extend .justify-content-center;
                img {
                    height: 75%;
                    @extend .align-self-center;
                }
            }
            &.active {
                background: none;
                color: theme-color('primary');
                .nav-icon {
                    filter: grayscale(0);
                }
            }
        }
    }

    &--features {
        z-index: +1;
        top: -.5rem;
        position: relative;
        // bottom: 1.25rem;
        // left: 1.25rem;

        .nav-item {
            // color: $white;
            .icon {
                max-height: 1rem;
            }

            @extend .ml-1;
            @extend .mr-0;
        }

        .tooltip-arrow,
        .tooltip>.tooltip-inner {
            background-color: #f00;
        }
    }

    &--language {
        .nav-link {
            &.is-current {
                color: theme-color(primary) !important;
            }
        }
    }

    &--utility {
        .nav-link {
            color: theme-color('primary-2') !important;
            @extend .px-1;
        }

        .nav-item {
            font-size: .9em;
            @extend .ml-0;
            @extend .mr-0;
        }
    }

    &--dossier {
        hr {
            @extend .pb-2;
        }
        .card {
            @extend .mb-0;
            .card-body {
                @extend .pb-0;
            }
        }
    }

    &--siblings {
        li {

            &:hover {
                a {
                    color: theme-color('primary');
                }
            }
        }
    }


    &--social {
        .nav-item {
            font-size: .9em;
            @extend .ml-1;
            @extend .mr-0;
        }

        .nav-link {
            background: white;
            display: block;
            width: 2em;
            height: 2em;
            position: relative;

            i {
                color: theme-color('primary-2');
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-right: -50%;
                transform: translate(-50%, -50%)
            }

            &:hover {
                background: theme-color('primary-3');
                // color: color('white');
            }
        }
    }

    &--meta {
        .nav-link {
            @extend .pb-0;
            @extend .px-1;
            text-decoration: underline;
            color: $white !important;
        }

        .nav-item {
            position: relative;

            &:after {
                content: '/';
                position: absolute;
                top: 0.65em;
                left: -.125em;
            }

            &:first-child {
                &:after {
                    content: '' !important;
                }
            }

        }
    }

    &--footer {
        font-size: 1.25em !important;

        .nav-link {
            @extend .px-md-2;
        }

        .nav-item {
            @extend .d-none;
            @extend .d-md-block;

            &:last-child {
                a {
                    @extend .pr-0;
                }
            }
        }
    }
}

.navbar-spy {
    background: white;
    
    // @extend .border-bottom-dashed;
    border-bottom: none;
    @extend .justify-content-center;
}


.navbar-utility {
    background-image: url('../../assets/img/Background-dark.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @extend .text-light;
    a, .nav-link {
        text-decoration: none;
    }
    p, .nav-item {
        font-size: .875rem;
        &.utility-phone {
            .icon {
                width: 1rem;
                margin-top: -.2em;
                margin-right: .5rem;
            }
        }
    }
}