// 
// 
// user-variables.scss

$font-size-base:              1.125rem ; // Assumes the browser default, typically `16px`

//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

$top-offset:              105px;

$primary:                   #D35230; //light: #FF8F6B
$primary-2:                 #8196A1;
$primary-3:                 #282828;
$primary-4:                 #B93415;
$primary-5:                 #F3F7F9;

$body-color:                $primary-2;
$headings-color:            $primary-3;

$font-family-sans-serif:    'IBM Plex Sans Regular',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ;
$font-family-base:          'IBM Plex Sans Regular', Sans-Serif;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$element-margin-bottom:   1.5rem;


$theme-color-scheme: (
    "primary":              $primary,
    "primary-2":            $primary-2,
    "primary-3":            $primary-3,
    "primary-4":            $primary-4,
    "primary-5":            $primary-5,
);

$theme-colors: (
    "primary":              $primary,
    "primary-2":            $primary-2,
    "primary-3":            $primary-3,
    "primary-4":            $primary-4,
    "primary-5":            $primary-5,
);

$link-color:                theme-color(primary-2);
$link-hover-color:          theme-color(primary);

$border-radius:             0px;
$border-width:              2px;
$border-color:              $primary;

$font-family-sans-serif:    'IBM Plex Sans', sans-serif;
$font-family-serif:         'IBM Plex Sans', sans-serif;
$font-family-base:          'IBM Plex Sans', sans-serif;
$font-family-condensed:     'sofia-pro', sans-serif;
$headings-font-family:      'sofia-pro', sans-serif;

$font-weight-light:         400;
$font-weight-normal:        400;
$font-weight-medium:        700;
$font-weight-bold:          700;
$font-weight-black:         900;
$lead-font-weight:          400;

$headings-font-weight:      400;
$display1-weight:           400;
$display2-weight:           400;
$display3-weight:           400;
$display4-weight:           400;

$display1-size:               6rem ;
$display2-size:               5.5rem ;
$display3-size:               3.5rem ;
$display4-size:               3rem ;

// $breadcrumb-padding-y:   0;
$breadcrumb-padding-x:      0;
$breadcrumb-margin-bottom:  0;
$breadcrumb-bg:             $white;
$breadcrumb-active-color:   $primary !important;
// $breadcrumb-item-padding:     
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1380px
);

$default-section-padding: $grid-gutter-width*4;