
.slider {
    // .carousel-cell {
    //     background-size: cover;
    // }

    // .is-nav-selected {
    //     h3 {
    //         color: red;
    //     }
    // }

    // &.slider-nav {
    //     @extend .col-md-6;
    //     postition: relative;
    //     top: -8em;
    // }
}

.slider {
    margin-left: -$grid-gutter-width*1.5 !important;
    margin-right: -$grid-gutter-width*.5 !important;

    &--main {

    }
    &--remote {
        font-family: $font-family-sans-serif;
        position: relative;
        top: -$grid-gutter-width;
        display: block;
        padding: $grid-gutter-width;
        margin-left: $grid-gutter-width*1.5 !important;
        margin-right: $grid-gutter-width*1.5 !important;
        .carousel-cell {
            width: 100%; /* full width */
        }
        p {
            @extend .pl-6;
        }
        .slick-arrow {
            // background: none;
            position: absolute;
            opacity: 1 !important;
            color: theme-color(primary-4);
            right: 0;
            top: $grid-gutter-width*1.5;
            width: 2rem;
            height: 2rem;
            &.slick-prev {
                right: $grid-gutter-width*3;
                left: initial;
            }
            &.slick-next {
                right: $grid-gutter-width;
            }
        }  
    }
    &--count {
        position: absolute;
        top: 0;
        left: 0;
        color: theme-color('primary-2');
        span {
            font-weight: bold;
        }
    }
}
