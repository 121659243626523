.border-top-dashed {
    border-top: none;
    border-bottom: none;
    background-image: linear-gradient(to right, theme-color('primary-3') 25%, rgba(255,255,255,0) 0%);
    background-position: top;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    .bg-primary &,
    .bg-dark & {
        background-image: linear-gradient(to right, $white 25%, rgba(255,255,255,0) 0%);
        &:before {
            color: $white;
        }
    }
}

.border-bottom-dashed {
    border-top: none;
    border-bottom: none;
    background-image: linear-gradient(to right, theme-color('primary-3') 25%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    .bg-primary &,
    .bg-dark & {
        background-image: linear-gradient(to right, $white 25%, rgba(255,255,255,0) 0%);
        &:before {
            color: $white;
        }
    }
}

.hidden {
    visibility: hidden;
}

hr {
    @extend .border-top-dashed;
}

.backendLink {
    font-size: 11px;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid theme-color(primary-4);
    color: theme-color(primary-4);
    background: #fff;
    @extend .px-1;
    opacity: 0.8;
    z-index: 99999999;
}

.bg-gradient-primary {
    background-image: linear-gradient( -90deg, theme-color('primary'), #ff8f6b );
}

.bg-light {
    background-color: theme-color('primary-5') !important;
}

.decoration.center {
    position: absolute;
    left: 50%;
    // top: 50%;
    
    /*
    Nope =(
    margin-left: -25%;
    margin-top: -25%;
    */
  
    /* 
    Yep!
    */
    transform: translate(-50%, 0%);
  }