.section {
    position: relative;

    @extend .py-7;

    &--content {
        position: relative;
        z-index: +1;
    }

    &--header {
        width: 100%;
        margin: 20px auto;
        overflow: hidden;
        text-align: center;
        line-height: 1.2em;
        &:before,
        &:after {
            content: "";
            vertical-align: middle;
            display: inline-block;
            width: 50%;
            margin: 0 2% 0 -55%;
            height: 1px;
        }
        &:after {
            margin: 0 -55% 0 2%;
        }
        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    &--newsletter {
        background-size: cover;
    }

    .background--header {
        padding-top: 2rem;
        text-align: center;
        z-index: -1;
    }

}