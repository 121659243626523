.jonnitto-prettyembed {
    &--audio {
        audio {
            width: 100%;
        }
    }
}

.response {
    
    &--schreiben-sie-uns {
        $button-height: 3em;
        position: relative;
        a, span {
            transition: all 0.5s ease-out;
            background: theme-color('primary');
            height: $button-height;
            border-radius: $button-height/2;
            color: theme-color('primary-2');
            @extend .pl-6;
            @extend .d-block;
            @extend .py-2;
        }
        i {
            position: absolute;
            font-size: 3em;
            left: .5em; 
            top: -.5em;
            transition: all 0.15s ease-out;
        }
        &:hover  {
            i {
                transition: all 0.15s ease-out;
                top: -.25em;
                color: theme-color('primary-2');
            }
        }
    }
}
