footer {
    font-family: $font-family-sans-serif;
    // font-size: 0.85rem;
    background: theme-color('primary-3');
    @extend .text-white;
    @extend .my-0;

    hr {
        @extend .m-0;
        @extend .pb-4;
    }

    h2, h3, h4 {
        @extend .text-white;
        @extend .mb-4;
        font-weight: $font-weight-normal !important;
    }

    a {
        color:#7D8C89 !important;
    }

    ul {
        list-style-type: none;
        padding-left: 0;

        li {

            line-height: 1.5em;
            &.nav-item {
                a {
                    color:#7D8C89 !important;
                    &:hover {
                        color: $white !important;
                    }
                }
            }
        }
    }

    >.container {
        @extend .pb-4;

        .backdrop-white & {
            background: $white;
        }
    }

    @extend .pb-3;
    @extend .pt-0;

    .container {
        @extend .d-flex;
        @extend .flex-column;
        @extend .pt-4;
    }

    .legal-notes {
        @extend .small;
        @extend .d-flex;
        @extend .container;
    }

}
