// 
// 
// navbar.scss
//
//

.navbar{
  border-bottom: $border-width solid rgba($primary-3,.08);
  width: 100%;
  &[data-overlay]{
    position: absolute;
  }
  &[data-sticky="top"]{
    transition: all $transition-time ease;
    &.scrolled{
      &.navbar-dark{
        background: theme-color('primary-3');
        border-bottom-color: rgba(0,0,0,0);
      }
      &.navbar-light{
        background: rgba($white,.5) !important;
        backdrop-filter: blur(20px);
        max-height: 90px;
      }
    }
  }
  &[data-overlay],&[data-sticky="top"]{
    z-index: (($zindex-dropdown)-1);
    &.scrolled{
      z-index: $zindex-fixed;
    }
  }
  .dropdown-menu{
    z-index: $zindex-popover;
  }
  .nav-link{
    transition: color $transition-time ease;
  }
}

.navbar-container{
  position: relative;
}

.navbar-light{
  .navbar-toggler{
    svg{
      path{
        fill: theme-color('dark');
      }
    }
  }
}

.navbar-dark{
  border-bottom: $border-width solid rgba($white,.08);
  .navbar-toggler{
    svg{
      path{
        fill: $white;
      }
    }
  }
  &.navbar-toggled-show{
    .dropdown-toggle{
      > svg.bg-dark{
        *{
          fill: $white;
        }
      }
    }
  }
}

.navbar-toggler{
  border: none;
  position: relative;
  &[aria-expanded="false"]{
    .navbar-toggler-close{
      opacity: 0;
      transform: scale(0) rotate(45deg);
    }
  }
  &[aria-expanded="true"]{
    .navbar-toggler-open{
      opacity: 0;
      transform: scale(0) rotate(-45deg);
    }
  }
}

.navbar-toggler-open, .navbar-toggler-close{
  transition: opacity $transition-time ease, transform $transition-time ease;
  opacity: 1;
  transform: scale(1);
}

.navbar-toggler-close{
  position: absolute;
  top: 0;
  left: 0;
}

@include media-breakpoint-up(lg){
  .navbar{
    padding-left: 0;
    padding-right: 0;
    .dropdown-menu{
      margin-top: 1rem;
    }
  }
}

nav[data-overlay].navbar-toggled-show{
  background: $white;
  &.navbar-dark{
    background: theme-color('primary-3');
    .dropdown-item{
      color: rgba($white,.5);
      &:hover{
        color: $white;
      }
    }
  }
}

@include media-breakpoint-down(md){
  .navbar-collapse{
    max-height: 100vh;
    overflow-y: scroll;
  }
}

// Navbar Dropdowns

@include media-breakpoint-down(md){
  .nav-item.dropdown{
    .col-auto{
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}