.linklist {
    .linklist {
        &--item {
            display: block;
            position: relative;
            padding-left: 1.5rem;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            @extend .border-top-dashed;

            .textBlock,
            .textBlock p {
                @extend .my-0;
                @extend .mb-0;
            }

            &:before {
                content: "→";
                display: block; 
                position: absolute;
                left: 0;
                top: .75rem;
                color: theme-color('primary');
            }
        }
        &--image {
            @extend .d-flex;
            @extend .align-items-center;
            padding-left: 0;
            img,
            .textBlock,
            .textBlock p {
                @extend .my-0;
            }

            img {
                @extend .mr-2;
            }

            &:before {
                content: '';
            }
        }
    }
    i, em {
        font-style: initial !important;
        opacity: 0.7;
    }

}