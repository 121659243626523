.breadcrumb {
    font-family: $font-family-sans-serif;
    .breadcrumb-item {
        a {
            opacity: 1;
            font-size: 1rem;
        }
        &::before {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB3aWR0aD0iMTc5MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIwMyA1NDRxMCAxMy0xMCAyM2wtMzkzIDM5MyAzOTMgMzkzcTEwIDEwIDEwIDIzdC0xMCAyM2wtNTAgNTBxLTEwIDEwLTIzIDEwdC0yMy0xMGwtNDY2LTQ2NnEtMTAtMTAtMTAtMjN0MTAtMjNsNDY2LTQ2NnExMC0xMCAyMy0xMHQyMyAxMGw1MCA1MHExMCAxMCAxMCAyM3oiLz48L3N2Zz4=");
            background-size: 20px;

            content: '';
        }
        .link-node {
            color: theme-color(primary-2) !important;
        }
        
        &.active {
            &::before {
            }
            .link-node {
                color: theme-color(primary-3) !important;
            }
        }
        &.current {
            &::before {
                opacity: 1;
            }
            .link-node {
                color: theme-color(primary-3) !important;
            }
        }
    }
}

ol.breadcrumb {
    background: none !important;
}




