// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

@import 'components/accordion';
@import 'components/buttons';
@import 'components/cards';
@import 'components/breadcrumb';
@import 'components/footer';
@import 'components/forms';
@import 'components/header-section';
@import 'components/latestNews';
@import 'components/layout';
@import 'components/linkList';
@import 'components/lists';
@import 'components/loader';
@import 'components/maps';
@import 'components/modals';
@import 'components/multimedia';
@import 'components/navigation';
@import 'components/process';
@import 'components/sections';
@import 'components/slider';
@import 'components/stoerer';
@import 'components/tables';
@import 'components/tabs';
@import 'components/typography';
@import 'components/utility';

$grid-gutter-widths: (
  sm: 20px,
  md: 30px,
  lg: 50px
);

@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-widths {
    @include media-breakpoint-up($grid-breakpoint) {
      $grid-gutter-half-width: $grid-gutter-width / 2;
      .row:not(.no-gutters) {
        margin-right: -$grid-gutter-half-width;
        margin-left: -$grid-gutter-half-width;
      }
      .row:not(.no-gutters) > .col,
      .row:not(.no-gutters) > [class*="col-"] {
        padding-right: $grid-gutter-half-width;
        padding-left: $grid-gutter-half-width;
      }
      .container {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
      }
    }
}
