@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');

p {
    a {
        text-decoration: underline;
    }
}

a {
    font-weight: $font-weight-normal;
    color: $link-color;
}


h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4,
.display-1,
.display-2,
.display-3,
.display-4 {
    letter-spacing: -.01em;
    line-height: 1.3em;
    color: theme-color('primary-3');
    .text-white & {
        color: $white !important;
    }
}


main {
    .textBlock {
        margin-bottom: $element-margin-bottom;

        ol {
            list-style-type: none;
            counter-reset: item;
            margin: 0;
            padding: 0;
        }

        ol>li {
            display: table;
            counter-increment: item;
            margin-bottom: 0.6em;
        }

        ol>li:before {
            content: counters(item, ".") ". ";
            display: table-cell;
            padding-right: 0.6em;
        }

    }

    ul {
        list-style-type: none;

        padding-left: 1.5em;

        li:not(.nav-item) {
            &:before {
                content: '➔' !important;
                position: absolute;
                margin-left: -1.5em;
                color: theme-color('primary-2');
            }
        }
    }

    ul,
    ol {
        padding-left: 1.5em;
        margin-bottom: $element-margin-bottom;
    }
}

ul {
    &.list-unstyled {
        list-style-type: initial;
        padding-left: initial;

        li {
            &:before {
                content: initial;
                margin-left: initial;
                color: initial;
                position: initial;
                display: none;
            }
        }
    }
}


p {
    &.h1 {
        @extend h1;
    }

    &.h2 {
        @extend h2;
    }

    &.h3 {
        @extend h3;
    }

    &.h4 {
        @extend h4;
    }

    &.h5 {
        @extend h5;
    }
}

.comicSans {
    font-family: "comic neue";
}

.border-top {
    border-top: 2px solid $primary-2 !important;
    @extend .pt-3;
}

h1,
.h1 {
    // .bg-light &{
    //     padding-bottom: 8rem;
    // }
    @extend .mb-5;
    font-size: $font-size-base*2.667;

    @include media-breakpoint-down(md) {
        font-size: $font-size-base*2.135;
    }
}

h2,
.h2 {
    font-size: $font-size-base*2;

    @include media-breakpoint-down(md) {
        font-size: $font-size-base*1.423;
    }
}

h3,
.h3 {
    font-size: $font-size-base*1.333;
    color: theme-color('primary-2');

    @include media-breakpoint-down(md) {
        font-size: $font-size-base*1.245;
    }
}

h4,
.h4 {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    // text-transform: uppercase;
    font-weight: $font-weight-normal;
    letter-spacing: 0.5px;
}

.lead {
    font-size: $lead-font-size !important;
    font-family: $font-family-base;
    font-weight: $lead-font-weight;
    font-style: normal;

    @extend .mb-4;

    @include media-breakpoint-down(md) {
        font-size: $font-size-base*1.066;
    }
}


.heading--decorated {
    font-size: 1rem;
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
    padding: 0;
    position: relative;
    overflow: hidden;
    margin: 0 0 1.5rem;

    &:before,
    &:after {
        content: " ";
        position: absolute;
        top: 50%;
        margin-left: -999em;
        height: 2px;
        width: calc(999em - 1.5em);
        @extend .border-top-dashed;
    }

    &:after {
        left: auto;
        width: 999em;
        margin: 0 0 0 1.5em;
    }
}
