body {
    position: relative;
    // background: #282828;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.wrapper {
    //     // padding-top: $top-offset;
    //     // padding-top: 80px;
    margin: 0 auto;
    // background: $white;
    overflow-x: hidden;
    // max-width: 1440px;
    //     // max-width: 100%;
    //     width: 100%;
    //     overflow: hidden;
    //     // padding-bottom: 3em;
    //     // > .row {
    //     //     background: orange;
    //     // }
}

.full-width {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    position: relative;
}


.bg-white {
    background-color: white;
}

.color-primary {
    color: theme-color('primary');
}

// .sticky-offset {
//     top: 200px !important;
// }

.section {
    &.bg-light {
        // .container {
        //     padding-top: 8rem;
        // }
    }


    padding-top: 0;

    &--main {}

    &--home {
        main {
            @extend .col-md-12;
        }
    }

    &--content {}

    &--stories,

    &--story {
        padding-top: 0 !important;
    }
}

.noBg {
    .navbar {
        &.bg-white {
            // background: #EFEFEF !important;
        }
    }

    .breadcrumb {
        background: #EFEFEF;
    }

    section {
        &:first-child {
            padding-top: $grid-gutter-width*4;

            @include media-breakpoint-down (md) {
                padding-top: $grid-gutter-width*3;
            }

            @include media-breakpoint-down (sm) {
                padding-top: $grid-gutter-width*2;
            }
        }
    }

}

figure {
    margin: 0 0 1rem;
}

img {
    @extend .img-fluid;
}

.story {
    &--visual {
        @extend .mt-0;
        @extend .mb-4;
        border-radius: 1rem;
    }
}
