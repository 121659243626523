.modal {
    .modal-dialog {
        @extend .modal-lg;
    }
    
    .modal-content {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
}

.modal {
    p {
        color: $white;
    }
    .close {
        position: absolute;
        top: $grid-gutter-width*2;
        right: $grid-gutter-width*2;
        z-index: +1;
        opacity: 1;
        @include media-breakpoint-down (lg) {
          top: $grid-gutter-width*1.5;
          right: $grid-gutter-width*1.5;
        }
        @include media-breakpoint-down (md) {
          top: $grid-gutter-width;
          right: $grid-gutter-width;
        }
        svg {
          transform: scale(2);
          @include media-breakpoint-down (md) {
            transform: scale(1.5);
          }
          @include media-breakpoint-down (sm) {
            transform: scale(1);
          }
        }
      }
      // Formular
    
      .mauticform-button {
        @extend .btn-light;
      }
      .mauticform-selectbox {
        background: none;
        &:focus {
          border-color: $white;
          color: $primary;
        }
      }
      label {
        color: $white;
      }
      .mauticform-page-wrapper {
          color: $white;
      }

      textarea, .mauticform-input, .mauticform-selectbox {
        background-color: transparent;
        border: $border-width solid $white;
        color: $white;
        font-size: $font-size-base;

        &::placeholder {
            color: $white;
        }
      }


    &.modal-fullscreen {

        .modal-body {
            max-width: 100%;
        }

        .modal {
            &-dialog {
                width: 100vw;
                height: 100vh;
                margin: 0;
                padding: 0;
                max-width: none;
            }

            &-content {
                height: auto;
                height: 100vh;
                border-radius: 0;
                border: none;
            }
            
            &-body {
                @extend .container;
                .modal-inside {
                    @extend .mx-auto;
                    max-width: 800px;
                }
                overflow-y: auto;
            }
        }
    }
}
